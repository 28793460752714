.ConfirmPopUp {
  padding: 30px 50px;
  background-color: #fafafa;
  text-align: left;
  width: 460px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #333;
  border-radius: 4px;
}
.ConfirmPopUp .Select {
  text-align: left;
}
.ConfirmPopUp__SelectRow {
  text-align: left;
}
.ConfirmPopUp__title {
  font-weight: 700;
  font-size: 16px;
}
.ConfirmPopUp__body {
  margin-top: 30px;
}
.ConfirmPopUp__body textarea {
  resize: none;
}
.ConfirmPopUp__body textarea.Input_Error {
  border-color: #d0021b;
}
.ConfirmPopUp__body-textInput {
  margin-top: 10px;
}
.ConfirmPopUp__body-select {
  margin-top: 15px;
}
.ConfirmPopUp__footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.ConfirmPopUp__footer .ConfirmPopUp__footer__button:first-child:not(disabled){
  background-color: red;
}

.ConfirmPopUp__footer__button {
  padding: 10px 25px;
  width: 130px;
  background-color: black;
  text-align: center;
  border-radius: 100px;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Proxima Nova", Georgia, sans-serif;
}
.ConfirmPopUp__footer__button:first-child {
  margin-right: 15px;
}
