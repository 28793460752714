@page {
  size: A4;
}
.WorkRequestForm {
  text-align: center;
}
.WorkRequestForm__actionsRow {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}
th {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
td {
  font-size: 16px;
}
.WorkRequestForm {
  text-align: center;
}
.WorkRequestForm .divider {
  border-bottom: 1px solid black;
}
.WorkRequestForm__heading {
  font-size: 36px;
  margin-top: 40px;
  margin-bottom: 10px;
}
.WorkRequestForm__label {
  font-weight: 700;
  text-transform: capitalize;
}
.WorkRequestForm__labname {
  font-size: 18px;
}
.WorkRequestForm__requestId {
  font-size: 18px;
}
.WorkRequestForm__table {
  margin-bottom: 20px;
  text-align: left;
}
.WorkRequestForm__table thead th {
  padding-bottom: 10px;
}
.WorkRequestForm__table__row-clinic {
  margin-top: 30px;
}
.WorkRequestForm__table__row-patient tr, .WorkRequestForm__table__row-patient td {
  min-width: 200px;
}
.WorkRequestForm__table__row-dates tr, .WorkRequestForm__table__row-dates td {
  min-width: 200px;
}
.WorkRequestForm__table__row-description {
  width: 100%;
  padding-bottom: 30px;
}
.WorkRequestForm__dialog {
  width: 800px;
  height: 1131px;
  background-color: white;
  color: black;
}
.WorkRequestForm__dialog .popup-dialog__content {
  padding-top: 0;
}
.WorkRequestForm__dialog__content {
  color: black;
  background-color: white;
}
.WorkRequestForm__button {
  margin-top: 10px;
  padding: 10px 40px;
  background-color: black;
  text-align: center;
  border-radius: 100px;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Proxima Nova", Georgia, sans-serif;
  margin-right: 20px;
}
.WorkRequestForm__button:last-child {
  margin-right: 0;
}
.WorkRequestForm__ToothCharts {
 /*margin: 50px 0 100px 0;
  padding: 26px 50px 50px 50px;
  background-color: #FCFEFF;
  border-radius: 4px;
 */
  margin-top: 30px;
}
.WorkRequestForm__ToothCharts__heading > span {
  font-size: 17px;
  text-transform: capitalize;
}
.WorkRequestForm__ToothCharts__charts {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
th {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
td {
  font-size: 16px;
}
@media print {
  body {
    width: 21cm;
    height: 29.7cm;
   /*margin: 30mm 45mm 30mm 45mm;
   */
    background-color: white;
    color: black;
   /* change the margins as you want them to be. */
    margin: 60mm 75mm 60mm 75mm;
 }
  .WorkRequestForm {
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
 }
  .WorkRequestForm__ToothCharts {
   /*margin: 50px 0 100px 0;
    padding: 26px 50px 50px 50px;
    background-color: #FCFEFF;
    border-radius: 4px;
   */
 }
  .WorkRequestForm__ToothCharts__heading > span {
    font-size: 17px;
    text-transform: capitalize;
 }
  .WorkRequestForm__ToothCharts__charts {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
 }
  th {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
 }
  td {
    font-size: 16px;
 }
}
