.LoadingDialog.popup-dialog {
  background: #f5f5f5;
}
.LoadingDialog .popup-dialog-header {
  background: #253238;
  text-align: center;
}
.LoadingDialog__content {
  padding-bottom: 40px;
  color: #12171a;
}
.LoadingDialog__progress {
  text-align: center;
  padding-bottom: 30px;
}
