.Login {
  background-color: #f7f9fa;
  height: 100vh;
}
.Login__Form {
  background-color: white;
  padding: 70px 35px 0px 35px;
  height: 500px;
  width: 300px;
}
.Login__Form .Input_Text {
  border: solid 1px #e6edf0;
  box-shadow: none;
  letter-spacing: 0.8px;
}
.Login__Form .Input_Text::placeholder {
  color: #7a8f99;
  letter-spacing: 0.8px;
  font-family: 'Proxima Nova';
}
.Login__Form__Logo {
  font-size: 20px;
  color: gray;
  text-align: center;
  letter-spacing: 0.3em;
}
.Login__Form__Header {
  margin-top: 10px;
  padding: 15px;
  font-size: 16px;
  text-align: center;
  padding-bottom: 0;
  color: #12171a;
}
.Login__Form__FormError {
  height: 30px;
  white-space: nowrap;
}
.Login__Form__FormError .Error {
  font-size: 14px;
}
.Login__Form__Help {
  text-align: center;
}
.Login__Form__forgotText {
  margin-top: 20px;
  letter-spacing: 0.8px;
}
.Login__Form__forgotText a span {
  text-decoration: none;
  color: #405159;
}
.Login__Form__registerText {
  margin-top: 115px;
}
.Login__Form__registerText__LineOne {
  display: block;
  letter-spacing: 1px;
  font-size: 15px;
  padding-bottom: 5px;
}
.Login__Form__registerText__LineTwo {
  margin-top: 5px;
  letter-spacing: 0.9px;
  font-size: 13px;
}
.Login__Form__registerText__LineTwo span {
  text-decoration: none;
  color: #0661ff;
}
.Login__Form__FormButton {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.Login__Form .Button {
  width: 200px;
  padding: 15px 20px;
  margin: 0;
  font-size: 14px;
}
