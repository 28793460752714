/* home.style */
.LanguageBar {
  position: absolute;
  right: 0;
  padding: 50px 100px 0 0;
  font-size: 20px;
  color: #405159;
  font-family: 'Noto Sans SC', 'Proxima Nova', sans-serif;
}
.LanguageBar__divider {
  margin: 0 10px;
}
.LanguageBar__en {
  font-family: 'Proxima Nova', sans-serif;
}
.LanguageBar__en:hover {
  cursor: pointer;
}
.LanguageBar__zh {
  font-family: 'Noto Sans SC', sans-serif;
}
.LanguageBar__zh:hover {
  cursor: pointer;
}
.Breadcrumb {
  letter-spacing: 1px;
  font-size: 15px;
  font-family: 'Proxima Nova Semibold';
  color: #9b9b9b;
  margin-bottom: 15px;
  display: flex;
}
.Breadcrumb-active {
  color: #405159;
}
.Breadcrumb__text {
  line-height: 21px;
}
.Breadcrumb__icon {
  margin: 0 5px 0 1px;
}
.Header {
  z-index: 1;
  background-color: #253238;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 14px 0px rgba(0, 0, 0, .5);
 /*#696464;
 */
}
.Header__logo {
  display: inline-block;
  color: white;
  width: 229px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 2.4px;
}
.Header__logo a {
  color: inherit;
  text-decoration: none;
}
.Header__logo a:hover {
  color: inherit;
}
.Header__logo a:link {
  color: inherit;
}
.Header__logo a:visited {
  color: inherit;
}
.Header__logo a:active {
  color: inherit;
}
.Header__right-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.Header__search-wrapper {
  position: relative;
  display: inline-block;
  width: 250px;
  margin-right: 40px;
}
.Header__search-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  left: 14px;
  color: white;
  line-height: 32px;
  font-size: 14px;
}
.Header__search {
 /*display: inline-block;
  padding-left: 40px;
  margin-right: 40px;
  */
  position: relative;
  height: 32px;
  background-color: #4f595c !important;
}
.Header__search .Select-control {
  background-color: #4f595c !important;
  padding-right: 10px;
  border: none;
  border-radius: 0px;
  color: white;
  width: 100%;
}
.Header__search .Select-control .Select-placeholder {
  padding-left: 40px;
  color: white;
}
.Header__search .Select-control .Select-value {
  padding-left: 40px;
  height: 34px;
  padding-right: 10px;
  vertical-align: middle;
}
.Header__search .Select-control .Select-input {
  padding-left: 40px;
}
.Header__search .Select-control .Select-arrow-zone {
  display: none;
}
.Header__search::placeholder {
  color: white;
  display: inline-block;
  margin-left: 40px;
}
.Header__divider {
  display: inline-block;
  height: 40px;
  background: #2d4249;
  width: 1px;
  margin-right: 10px;
}
.Header__notifications {
  text-align: center;
  display: inline-block;
  margin-top: 8px;
  padding: 0 25px;
}
.Header__notifications:hover {
  cursor: pointer;
}
.Header__notifications--active .Header__notifications__button .notificationDot {
  fill: #a8b4ec;
}
.Header__notifications__button {
  display: inline-block;
  margin-right: 23px;
  margin-top: 7px;
}
.Header__notifications__button .notificationDot {
  fill: #253238;
}
.Header__notifications__dropdown {
  margin-top: 0;
  padding: 0;
  opacity: 1 !important;
  cursor: default;
}
.Header__notifications__dropdown.__react_component_tooltip.place-bottom {
  opacity: 1;
  box-shadow: none;
}
.Header__notifications__dropdown.__react_component_tooltip.place-bottom:after {
  left: auto;
  right: 7px;
  top: -10px;
  border-bottom-width: 10px;
  border-left-width: 4px;
  border-right-width: 4px;
}
.Header__user-info {
  display: flex;
  align-items: center;
  width: 280px;
}
.Header__user-photo {
  margin-right: 15px;
  display: inline-block;
}
.Header__user-info-right-wrapper {
  margin-right: 75px;
}
.Header__user-id {
  font-size: 14px;
  font-weight: bolder;
  color: white;
  display: block;
}
.Header__user-type {
  font-size: 12px;
  color: #7a8f99;
  display: block;
}
.Header__menu {
  width: 30px;
}
.Header__menu__dropdown.__react_component_tooltip.place-bottom {
  opacity: 1;
  box-shadow: 0 5px 10px 0px #f6f8f9;
}
.Header__menu__dropdown.__react_component_tooltip.place-bottom:after {
  left: auto;
  right: 6px;
}
.Header__menu__dropdown__menu__item {
  font-size: 16px;
  text-align: right;
  padding: 8px 0px;
  font-family: 'Proxima Nova';
}
.Header__menu__dropdown__menu__item:hover {
  cursor: pointer;
}
.Header__menu__dropdown a:hover {
  text-decoration: none;
}
.Header__menu__dropdown__text:hover {
  text-decoration: none;
}
.Header__menu__dropdown__text:hover span {
  text-decoration: none;
}
.Header__menu__dropdown__text span {
  text-decoration: none;
}
.Header__menu__popup a:hover {
  text-decoration: none;
}
.Header__menu__popup__text:hover {
  text-decoration: none;
}
.Header__menu__popup__text:hover span {
  text-decoration: none;
}
.Header__menu__popup__text span {
  text-decoration: none;
}
.Header__menu:hover {
  cursor: pointer;
}
.Header__menu__icon {
  font-size: 18px;
  color: white;
}
.Header__menu__menuItem {
  font-size: 18px;
  font-family: 'Proxima Nova', Georgia, sans-serif;
}
.Sidebar {
  width: 90px;
  padding-top: 40px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 8px 10px 0px #f6f8f9;
}
.Sidebar__link {
  stroke-width: 1.2;
  margin-bottom: 50px;
  text-align: center;
}
.Sidebar__link .Sidebar__svg {
  margin-left: 24px;
}
.Sidebar__link--active {
  stroke-width: 1.6;
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: #12171a;
}
.Sidebar__link--active .Sidebar__svg {
  margin-left: 20px;
}
.Sidebar__icon {
  stroke-width: 1.2;
  fill: white;
  stroke: #566973;
}
.Sidebar__icon-messaging {
  stroke-width: 0;
}
.Sidebar__icon-messaging--active path {
  stroke-width: 1;
}
.Sidebar__icon-notepad {
  stroke-width: 0;
}
.Sidebar__icon-notepad--active {
  stroke: #12171a;
  stroke-width: 0.5;
}
.Sidebar__icon--active {
  stroke: #12171a;
  stroke-width: 1.6;
}
.Loader {
  position: fixed;
  top: inherit;
  left: inherit;
  width: 100%;
  height: 100%;
  background: #fff url('../images/loader.gif') no-repeat center center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  background-size: 32px 32px;
  display: none;
}
.Loader__show {
  display: block;
}
