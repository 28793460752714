.OverarchingDialog-general .OverarchingDialog__content {
  padding-bottom: 0px;
}
.OverarchingDialog-loading .OverarchingDialog__content {
  padding-bottom: 20px;
}
.OverarchingDialog.popup-dialog {
  background: #f5f5f5;
}
.OverarchingDialog .popup-dialog-header {
  background: #253238;
  text-align: center;
}
.OverarchingDialog__content {
  color: #12171a;
}
.OverarchingDialog__progress {
  text-align: center;
  padding-bottom: 30px;
}
.OverarchingDialog .popup-dialog-footer {
  padding-top: 0;
}
