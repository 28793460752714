.Button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 16px;
    border-radius: 100px;
    color:white;
    letter-spacing:1.5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    background-color: black;
    text-align: center;
}

a.Button:hover, a.Button,
.Button > a:hover, .Button > a {
  text-decoration:none;
  color:inherit;
}


.Button__Block {
    display: block;
    width: 100%;
}

.Button__Uppercase {
    text-transform: uppercase;
}

.Button__Disabled {
    opacity: .5;
    cursor: default;
}

.Button:active,
.Button:focus {
    outline: none;
}

.Button__Size--large {
  padding: 10px 60px;
}

.Button__Size--small {
    padding: 5px 10px;
    font-size: 12px;
}

.Button__Theme--primary {
    background: #273238;;
    color: #fff;
    border-color: #273238;;
}

.Button__Theme--secondary {
    background: transparent;
    color: #fff;
    border-color: #273238;;
}
