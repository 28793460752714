.ToothSet {
  display: flex;
  flex-direction: column;
}
.ToothSet__UPPER #toothLow {
  pointer-events: none;
  stroke: lightgray;
}
.ToothSet__UPPER #toothLow line.cls-2 {
  stroke: lightgray;
}
.ToothSet__UPPER #toothLow .tooth {
  stroke: lightgray;
}
.ToothSet__LOWER #toothUp {
  pointer-events: none;
  stroke: lightgray;
}
.ToothSet__LOWER #toothUp line.cls-2 {
  stroke: lightgray;
}
.ToothSet__LOWER #toothUp .tooth {
  stroke: lightgray;
}
.ToothMovementChart__loadingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: gray;
  border-radius: 100px;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToothMovementChart__heading {
  margin-bottom: 20px;
  margin-top: 20px;
}
.ToothMovementChart__heading span {
  margin-left: 3px;
  color: gray;
}
.ToothMovementChart__charting {
  position: relative;
}
.ToothMovementChart__buttons {
  margin-bottom: 20px;
  margin-top: 20px;
}
.ToothMovementChart__buttons button {
  margin-top: 10px;
  padding: 10px 40px;
  background-color: black;
  text-align: center;
  border-radius: 100px;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Proxima Nova", Georgia, sans-serif;
}
.ToothMovementChart__HoverMenu__title {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  background-color: darkslategrey;
  color: white;
}
.ToothMovementChart__HoverMenu__item.selected {
  background-color: bisque;
}
.ToothMovementChart__HoverMenu__item:hover {
  cursor: pointer;
  background-color: lightgray;
}
g.move .tooth {
  stroke: slateblue;
  fill: slateblue;
}
g.move .tnum {
  fill: white;
}
g:hover {
  cursor: pointer;
}
g:hover .tooth {
  fill: lightgray !important;
}
g.missing .tooth {
  stroke: lightgray;
  fill: white;
}
g.missing .tnum {
  fill: lightgray;
}
g.implant .tooth {
  stroke: lightslategray;
  fill: lightslategray;
}
g.implant .tnum {
  fill: white;
}
g.crown .tooth {
  stroke: lightslategray;
  fill: lightslategray;
}
g.crown .tnum {
  fill: white;
}
g.bridge .tooth {
  stroke: lightslategray;
  fill: lightslategray;
}
g.bridge .tnum {
  fill: white;
}
g.inlay .tooth {
  stroke: lightslategray;
  fill: lightslategray;
}
g.inlay .tnum {
  fill: white;
}
g.onlay .tooth {
  stroke: lightslategray;
  fill: lightslategray;
}
g.onlay .tnum {
  fill: white;
}
.tooth {
  stroke: black;
  fill: white;
}
.ToothSet line {
  stroke: black;
}
