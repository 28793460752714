.ActivityLog {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6edf0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  overflow-y: scroll;
  max-height: 40vh;
  position: relative;
}
.ActivityLog::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  right: 0;
}
.ActivityLog::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .1);
  border-radius: 10px;
}
.ActivityLog::-webkit-scrollbar-thumb:hover {
 /*background-color: rgba(0,0,0,.1);
 */
  cursor: default;
}
.ActivityLog__Message {
  padding: 10px 15px;
  width: 300px;
  border-bottom: solid 1px lightgrey;
  color: #12171a;
  font-size: 11px;
  font-family: 'Proxima Nova';
}
.ActivityLog__Message-read {
  background-color: #fff;
}
.ActivityLog__Message:hover {
  cursor: pointer;
  opacity: 0.93;
 /*background-color: rgba(239,239,239,.5)*/
}
.ActivityLog__Message__Date {
  padding-top: 10px;
  text-align: right;
  font-size: 10px;
  color: gray;
}
.ActivityLog__Message:last-child {
  border-bottom: none;
}
