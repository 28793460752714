/* forms.style */
.bdTimePicker > .rc-time-picker-input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a;
  height: 40px;
}
.bdDateTimePicker > .react-datetime-picker__wrapper {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  min-width: 280px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a !important;
  height: 40px;
}
.bdDateTimePicker.react-datetime-picker--disabled > .react-datetime-picker__wrapper {
  background-color: #f0f0f0;
}
.bdDateTimePicker.react-datetime-picker--disabled > .react-datetime-picker__wrapper input.react-datetime-picker__inputGroup__minute:disabled {
  background-color: transparent;
}
.bdDateTimePicker.react-datetime-picker--disabled > .react-datetime-picker__wrapper input:disabled {
  background-color: #f0f0f0;
}
.bdDateTimePicker > .react-datetime-picker__wrapper {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  min-width: 280px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a !important;
  height: 40px;
}
.ScheduleAddDentistForm {
  width: 500px;
  height: 300px;
}
.EditPatientForm {
  width: 700px;
  height: 820px;
}
.CreateUserAccountForm {
  width: 700px;
  height: 650px;
}
.InsuranceForm {
  width: 700px;
  height: 710px;
}
.QuickPatientForm {
  width: 700px;
  height: 440px;
}
.AppointmentForm {
  width: 700px;
 /*height:690px;
 */
}
.ImageUploadForm {
  width: 872px;
  height: 810px;
}
.Overlay {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.Content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 100px);
}
.Content:focus {
  outline: 0;
}
.DialogFormContent {
  padding-bottom: 0;
}
.Buttons {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  padding: 0 54px;
}
.Input_Error .Error__Label {
  overflow: auto;
}
.Error {
  color: #d0021b;
}
.Error__Label {
  height: 30px;
  padding: 5px 5px;
}
.Select-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.Input_Error .Select-control {
  border-color: #d0021b;
}
.Input_Error {
  border-color: #d0021b;
}
.Input_Error:focus {
  border-color: #d0021b;
}
.Button:disabled {
  border-color: #999;
}
.Button {
  font-size: 13px;
  margin-left: 10px;
  letter-spacing: 0.9px;
  text-align: center;
  line-height: 19px;
  border-radius: 100px;
  width: 100px;
}
.Button__Cancel {
  color: #12171a;
  border: 1px solid #273238;
  padding: 5px 0;
}
.Button__Save {
  color: #fff;
  background: #273238;
  padding: 5px 0;
}
.Button__Stop {
  border: 1px solid #367fff;
  color: #367fff;
  padding: 5px 0;
}
.Button__Start {
  color: #fff;
  background: #367fff;
  padding: 5px 0;
}
.ModalForm {
  z-index: 5;
}
.ModalForm__Container {
  font-family: 'Proxima Nova', Georgia, sans-serif;
  background: #f7f9fa;
  border: 1px solid #ededed;
  border-radius: 4px 4px 0 0;
 /*box-shadow: 1px 3px 20px 20px rgba(239,239,239,0.50);
 */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  text-align: left;
  padding: 50px 70px 60px 70px;
}
.ModalForm__Header {
  font-size: 18px;
  color: #12171a;
  letter-spacing: 0.83px;
  line-height: 26px;
}
.ModalForm__SubHeader {
  margin-top: 5px;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.83px;
  line-height: 19px;
}
.ModalForm__Buttons {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
}
.ModalForm__Buttons .Button {
  font-size: 13px;
  letter-spacing: 0.9px;
  text-align: center;
  line-height: 19px;
  border-radius: 100px;
}
.ModalForm__Buttons .Button__Cancel {
  color: #12171a;
  border: 1px solid #273238;
  padding: 5px 22px 5px 23px;
}
.ModalForm__Buttons .Button__Save {
  color: #fff;
  background: #273238;
  padding: 5px 33px 5px 32px;
  margin-left: 10px;
}
.ModalForm__Buttons .Button__Stop {
  border: 1px solid #367fff;
  color: #367fff;
}
.ModalForm__Buttons .Button__Start {
  color: #fff;
  background: #367fff;
}
.ModalForm__Inputs {
  margin-top: 35px;
  display: flex;
}
.ModalForm__Inputs.One {
  justify-content: space-evenly;
}
.ModalForm__Inputs.Two {
  justify-content: space-between;
}
.ModalForm__Inputs.Three {
  justify-content: space-between;
}
.ModalForm__Inputs.AddaPatient {
  justify-content: flex-start;
}
.ModalForm__Inputs .Input.One {
  width: 100%;
}
.ModalForm__Inputs .Input.Two {
  width: 45%;
}
.ModalForm__Inputs .Input.Three {
  width: 30%;
}
.ModalForm__Inputs .Input__Label {
  font-size: 14px;
  color: #12171a;
  letter-spacing: 0.78px;
  line-height: 26px;
}
.ModalForm__Inputs .Input__Text {
  background: #fff;
  border: 1px solid #c7d3d9;
  border-radius: 3px;
}
.ModalForm__Inputs .Input__Radio {
  font-size: 13px;
  color: #9b9b9b;
  letter-spacing: 0.9px;
  line-height: 19px;
  text-align: center;
  background: #fff;
  border: 1px solid #c7d3d9;
  border-radius: 3px;
}
.ModalForm__Inputs .Input__Radio --selected {
  color: #000;
  border-color: #273238;
}
.VideoControllerContainer {
  padding: 0 30px;
}
@keyframes blinker {
  from {
    opacity: 1;
 }
  to {
    opacity: 0;
 }
}
.VideoController {
  display: flex;
  padding: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.VideoController__VideoPlayer {
  width: 600px;
}
.VideoController__VideoPlayer.dropzone {
  margin: 5px;
  height: 400px;
  width: 600px;
}
.VideoController__VideoPlayer__Recording {
  position: absolute;
  z-index: 1;
  top: 0;
}
.VideoController__VideoPlayer__Recording-Icon {
  margin-top: 8px;
  margin-left: 5px;
  animation: blinker 0.8s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
.VideoController__VideoPlayer__VideoScreen {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px 0 5px;
  min-height: 300px;
}
.VideoController__VideoPlayer__VideoButtons {
  width: 100%;
}
.VideoController__VideoPlayer__VideoButtons__Buttons {
  justify-content: flex-start;
}
.VideoController__VideoPlayer__VideoButtons__Buttons .Button {
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.VideoController__VideoList {
  width: 300px;
  height: 410px;
  border-left: 1px solid #ccc;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.VideoController__VideoList.dropzone {
  border: none;
  border-left: 1px dotted #ccc;
}
.VideoController__VideoList__Video {
  padding: 5px;
}
.VideoController__VideoList .dz-preview {
  align-self: center;
  flex-shrink: 0;
  margin-bottom: 10px;
}
.filepicker {
  font-family: sans-serif;
}
div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #e1e1e1;
  border-radius: 5px;
  min-height: 60px;
  border: 2px dashed #c7c7c7;
}
/* Icon */
.filepicker-file-icon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;
  color: black;
}
.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;
  width: 29px;
  height: 34px;
  content: '';
  border: solid 2px #7f7f7f;
  border-radius: 2px;
}
.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;
  position: absolute;
  top: 9px;
  left: -4px;
  padding: 0 2px;
  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
}
.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;
  width: 0;
  height: 0;
  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035;
}
